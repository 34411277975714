<template>
  <div
    class="h-screen flex w-full items-center justify-center"
    style="background-color: white"
  >
    <div
      :class="getDentalVersionActive ? 'bg-image-dental' : 'bg-image-kuyum'"
      class="bg-image vx-col hidden md:block lg:w-8/12 md:w-6/12 p-8 h-full"
    >
      <div class="w-full h-full flex items-center justify-center">
        <div class="w-full text-center">
          <p style="font-size: 22px" class="w-full text-white mb-4">
            {{ $t("Register_Title_1") }}
          </p>
          <p
            style="font-size: 36px; font-weight: 600"
            v-if="!getDentalVersionActive"
            class="w-full text-white"
          >
            {{ $t("Login_Title_2") }}
          </p>

          <p
            style="font-size: 36px; font-weight: 600"
            v-else
            class="w-full text-white"
          >
            {{ $t("Login_Title_3") }}
          </p>
        </div>
      </div>
    </div>
    <div class="sm:w-full lg:w-4/12 md:w-6/12">
      <div class="flex justify-center">
        <div class="p-12">
          <div class="mb-4">
            <img
              v-if="!getDentalVersionActive"
              src="@/assets/images/pages/novafab-logo.png"
              alt=""
            />
            <img v-else src="@/assets/images/pages/dentafab-logo.png" alt="" />
          </div>

          <div>
            <vs-input
              color="dark"
              :danger="userNameControl"
              name="email"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather"
              :label-placeholder="$t('Email')"
              v-model="data.username"
              class="w-full"
              @keyup.enter.native="sendRegisterRequest"
            />

            <vs-input
              color="dark"
              :danger="userPasswordControl"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              :label-placeholder="$t('Password')"
              v-model="data.password"
              class="w-full mt-6"
              @keyup.enter.native="sendRegisterRequest"
            />
            <vs-input
              color="dark"
              :danger="userConfirmPasswordControl"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              :label-placeholder="$t('Register_Confirm_Password')"
              v-model="data.confirmPassword"
              class="w-full mt-6"
              @keyup.enter.native="sendRegisterRequest"
            />
            <vs-input
              color="dark"
              :danger="companyControl"
              icon-no-border
              icon="icon icon-home"
              icon-pack="feather"
              :label-placeholder="$t('Company')"
              v-model="data.company"
              class="w-full mt-6"
              @keyup.enter.native="sendRegisterRequest"
            />
            <vs-alert class="mt-1" :active="authError" color="danger">{{
              errorMessage
            }}</vs-alert>

            <vs-button
              color="dark"
              class="w-full mb-6 mt-4"
              @click="sendRegisterRequest"
              >{{ $t("Register") }}</vs-button
            >
            <vs-button
              color="dark"
              type="border"
              to="/login"
              class="px-4 w-full"
              >{{ $t("Register_Back_To_Login") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue from "vue";
// Vue.use(VueReCaptcha, {
//   siteKey: "6LdsDmgcAAAAAON201A40-ZtB6lENZu0Gr_xxXgQ",
// });

export default {
  data() {
    return {
      userNameControl: false,
      userPasswordControl: false,
      userConfirmPasswordControl: false,
      companyControl: false,
      authError: false,
      errorMessage: null,

      data: {
        username: "",
        password: "",
        confirmPassword: "",
        company: "",
      },
    };
  },
  methods: {
    checkRecaptcha() {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("login").then((response) => {
          // APIYE ISTEK
        });
      });
    },
    sendRegisterRequest() {
      if (this.data.username === "") {
        this.userNameControl = true;
        this.errorMessage = this.$t("Login_Message_5");
        this.authError = true;
        return;
      } else if (this.data.password === "") {
        this.userPasswordControl = true;
        this.userNameControl = false;
        this.errorMessage = this.$t("Login_Message_6");
        this.authError = true;
        return;
      } else if (this.data.confirmPassword === "") {
        this.userPasswordControl = true;
        this.userNameControl = false;
        this.errorMessage = this.$t("Login_Message_6");
        this.authError = true;
        return;
      } else if (this.data.confirmPassword !== this.data.password) {
        this.userPasswordControl = true;
        this.userNameControl = false;
        this.errorMessage = this.$t("Register_Message_1");
        this.authError = true;
        return;
      } else if (this.data.company === "") {
        this.userPasswordControl = true;
        this.userNameControl = false;
        this.errorMessage = this.$t("Register_Message_2");
        this.authError = true;
        return;
      }
      this.userNameControl = false;
      this.userPasswordControl = false;
      this.userConfirmPasswordControl = false;
      this.companyControl = false;
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var userLanguage;
      if (navigator.language == "tr") {
        userLanguage = "tr";
      } else {
        userLanguage = "en";
      }

      var params = {
        email: this.data.username,

        company: {
          name: this.data.company,
        },
        password: this.data.password,
        language: userLanguage,
      };

      Api.post(API.BASEURL + API.REGISTER, this.controlRegister, params);
    },
    controlRegister(status, data) {
      this.$vs.loading.close();

      if (status >= 200 && status < 300) {
        this.$vs.dialog({
          color: "dark",
          title: this.$t("Register"),
          text: this.$t("Register_Message_3"),
          accept: this.acceptAlert,
          cancel: this.acceptAlert,
          close: this.acceptAlert,
          acceptText: this.$t("Accept"),
        });
      } else if (status >= 400 && status < 500) {
        if (data.data.email != null) {
          this.errorMessage = data.data.email[0];
          this.authError = true;
        }
      }
    },
    acceptAlert() {
      this.$router.push("/login").catch(() => {});
      // this.$recaptcha.value.hideBadge();
    },
  },
  computed: {
    getDentalVersionActive() {
      return this.$store.getters.getDentalVersionActive;
    },
  },
};
</script>

<style lang="scss">
.bg-image-dental {
  background-image: url("/img/dental-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
.bg-image-kuyum {
  background-image: url("/img/kuyum-login-bg.jpg");
  background-position: center;
  background-repeat: repeat;
}
</style>
